import React from 'react';
import { isEmpty } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Heading } from '@fiverr-private/typography';
import { NotableClient } from '../../interfaces';
import { BiEvents } from '../../interfaces/biEvents';
import { NotableClientsContext } from '../NotableClientsContext/NotableClientsContext';
import { NotableClientsCards } from './NotableClientsCards/NotableClientsCards';
import { NotableClientsModalButton } from './NotableClientsModalButton';
import { CLIENTS_TO_PREVIEW } from './NotableClients.config';

interface NotableClientsProps {
  notableClients: NotableClient[];
  biEvents: BiEvents;
  isAgency: boolean;
}

export const NotableClients: React.FC<NotableClientsProps> = ({ biEvents, isAgency, notableClients }) => {
  if (isEmpty(notableClients)) {
    return null;
  }

  const notableClientsPreview = notableClients.slice(0, CLIENTS_TO_PREVIEW);

  const notableClientsHeaderKey = isAgency ? 'gig_notable_clients.header_agency' : 'gig_notable_clients.header';

  return (
    <NotableClientsContext.Provider value={{ biEvents, notableClients }}>
      <Stack direction="column" gap="4">
        <Stack justifyContent="spaceBetween">
          <Heading as="h5">
            <I18n k={notableClientsHeaderKey} />
          </Heading>
          <NotableClientsModalButton />
        </Stack>
        <NotableClientsCards notableClients={notableClientsPreview} />
      </Stack>
    </NotableClientsContext.Provider>
  );
};
