import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Image } from '@fiverr-private/media';
import { NotableClientCardProps } from '../NotableClientsCards';
import { NotableClientCardWrapper } from './NotableClientCardWrapper';
import { ClientInfoTooltip } from './ClientInfoTooltip';

export const NotableClientCardRow: React.FC<NotableClientCardProps> = ({ notableClient }) => {
  const { enrichedDetails: { logo, name, industry, description } = {} } = notableClient;

  return (
    <NotableClientCardWrapper notableClient={notableClient}>
      <Stack gap="4" direction="column" alignItems="flexStart">
        {logo && <Image objectFit="contain" height={56} width="auto" src={logo} alt={name} />}
        <Stack direction="column" gap="0">
          <Stack alignItems="flexEnd">
            <Text fontWeight="bold">{name}</Text>
            <ClientInfoTooltip description={description} />
          </Stack>
          <Text color="bodySecondary">{industry}</Text>
        </Stack>
      </Stack>
    </NotableClientCardWrapper>
  );
};
