import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { BiEvents } from '../../interfaces/biEvents';
import { MetadataItemProps } from '../../interfaces';
import { GigInfoContext } from '../GigInfoContext/GigInfoContext';
import { MetadataItem } from './MetadataItem/MetadataItem';

export interface GigMetadataProps {
  gigMetadata: MetadataItemProps[];
  biEvents: BiEvents;
}

export const GigMetadata: React.FC<GigMetadataProps> = ({ gigMetadata, biEvents }) => (
  <GigInfoContext.Provider value={{ biEvents }}>
    <Stack direction="column">
      {gigMetadata.map(({ label, items }) => (
        <MetadataItem key={label} label={label} items={items} />
      ))}
    </Stack>
  </GigInfoContext.Provider>
);
