import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '@fiverr-private/gig_page_context';
import { TagType } from '@fiverr-private/tag_collection';
import { getBaseImpressionParams } from './base';

interface SellerTagsInGigPageIsShownProps extends BqEventEnrichmentDataProps {
    tags: string[];
    tagsToShow: number;
    type: TagType;
    metadataType: string;
}

export const bqSellerTagsInGigPageIsShown = ({
    tags,
    tagsToShow,
    type,
    metadataType,
    enrichmentData,
}: SellerTagsInGigPageIsShownProps) =>
    merge(
        {},
        getBaseImpressionParams('seller_decision_indicator_tags_in_gig_page_is_shown', enrichmentData), // di tags,
        {
            page: {
                component: {
                    name: 'decision_indicator_tags',
                    metadata: JSON.stringify({
                        names: tags.join(', '),
                        type: metadataType,
                        visible_elements: tagsToShow,
                    }),
                    type,
                    num_of_elements: tags.length,
                },
                element: {
                    key: `${tags.length}`,
                },
            },
        }
    );
