import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '@fiverr-private/gig_page_context';
import { TagType } from '@fiverr-private/tag_collection';
import { getBaseActionsParams } from './base';

interface BqClickedOnTagsShowMoreInGigPageProps extends BqEventEnrichmentDataProps {
    tags: string[];
    tagsToShow: number;
    type: TagType;
    metadataType: string;
}

export const bqClickedOnTagsShowMoreInGigPage = ({
    tags,
    tagsToShow,
    type,
    metadataType,
    enrichmentData,
}: BqClickedOnTagsShowMoreInGigPageProps) =>
    merge({}, getBaseActionsParams('clicked_on_decision_indicator_tags_show_more_in_gig_page', enrichmentData), {
        page: {
            component: {
                name: 'decision_indicator_tags',
                metadata: JSON.stringify({ names: tags.join(', '), type: metadataType, visible_elements: tagsToShow }),
                type,
                num_of_elements: tags.length,
            },
            element: {
                key: `${tags.length}`,
            },
        },
    });
