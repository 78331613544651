import { MetadataItemProps } from '@fiverr-private/gig_info';
import { sortAttributes, validateAttributes } from '../../components/Description/Metadata/utils';
import { Description, TranslatedMetadataAttribute } from '../../types/gig/interfaces';
import { getFormattedMetadataAttributes } from '../getFormattedMetadataAttributes/getFormattedMetadataAttributes';

interface PrepareGigPageInitialPropsToGigMetadataParams {
    description: Description;
}

interface PrepareGigPageInitialPropsToGigMetadataResult {
    gigMetadata: MetadataItemProps[];
}

export const prepareGigMetadata = ({
    description,
}: PrepareGigPageInitialPropsToGigMetadataParams): PrepareGigPageInitialPropsToGigMetadataResult => {
    const validAttributes: TranslatedMetadataAttribute[] = validateAttributes(description?.metadataAttributes);
    const sortedAttributes: TranslatedMetadataAttribute[] = sortAttributes(validAttributes);
    let gigMetadata: MetadataItemProps[] = [];

    if (!(sortedAttributes.length > 0)) {
        return {
            gigMetadata,
        };
    }

    gigMetadata = getFormattedMetadataAttributes(sortedAttributes);

    return {
        gigMetadata,
    };
};
