import React, { ReactElement } from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Heading } from '@fiverr-private/typography';
import { useImpressionableRef } from '../../hooks/useImpressionableRef';
import { mpAboutTheGigViewed } from '../../events/mixpanel/mpAboutTheGigViewed';
import { BiEvents } from '../../interfaces/biEvents';

type Theme = 'default' | 'business';

const themeToHeader = {
  default: () => translate<ReactElement>('gig_info.header.default'),
  business: () => translate<ReactElement>('gig_info.header.business'),
};

export interface GigInfoHeaderProps {
  theme?: Theme;
  biEvents: BiEvents;
  badge?: React.ReactNode;
}

export const GigInfoHeader: React.FC<GigInfoHeaderProps> = ({ theme = 'default', biEvents, badge = null }) => {
  const HeaderText = themeToHeader[theme];
  const ref = useImpressionableRef({
    onImpression: () => biEvents.sendMixPanelEvent(mpAboutTheGigViewed()),
  });

  return (
    <Stack gap="3">
      <Heading as="h5" ref={ref}>
        <HeaderText />
      </Heading>
      {badge}
    </Stack>
  );
};
