import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '../../interfaces/biEvents';
import { getBaseActionsParams } from './base';

export const bqClickedOnShowMoreAtGigDescription = ({ enrichmentData }: BqEventEnrichmentDataProps) =>
    merge({}, getBaseActionsParams('clicked_on_show_more_at_gig_description', enrichmentData), {
        page: {
            element: {
                type: 'button',
                text: 'Read More',
            },
            component: {
                name: 'gig_description',
            },
        },
    });
