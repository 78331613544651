import React, { ReactElement } from 'react';
import {
  FiverrIcon,
  LevelOneIcon,
  LevelTwoIcon,
  LightningIcon,
  StarIcon,
  TrophyIcon,
  VettedIcon,
  SuitcaseIcon,
} from '@fiverr-private/visuals';
import { translate } from '@fiverr-private/i18n-react';
import { HighlightType } from '../../interfaces';

const ICON_COLOR = 'grey_1100';
const ICON_SIZE = 'lg';

interface ExtraDataProps {
  sellerName: string;
}

interface TitleProps {
  isAgency?: boolean;
}
interface HighlightConfig {
  Icon: React.FC;
  Title: React.FC<TitleProps>;
  DescriptionText: React.FC<Partial<ExtraDataProps>>;
}

export const highlightsConfig = {
  pro: {
    Icon: () => <VettedIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('gig_seller_highlights.highlights.pro.title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.pro.description'),
  },
  loyaltySeller: {
    Icon: () => <TrophyIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('gig_seller_highlights.highlights.loyaltySeller.title'),
    DescriptionText: (params: Partial<ExtraDataProps>) =>
      translate<ReactElement>('gig_seller_highlights.highlights.loyaltySeller.description', { params }),
  },
  topRated: {
    Icon: () => <StarIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('levels.top_rated_seller.short_title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.topRated.description'), // should be reverted to levels.top_rated_seller.title_description after they change it
  },
  level2: {
    Icon: () => <LevelTwoIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('levels.level_two_seller.short_title'),
    DescriptionText: () => translate<ReactElement>('levels.level_two_seller.title_description'),
  },
  level1: {
    Icon: () => <LevelOneIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('levels.level_one_seller.short_title'),
    DescriptionText: () => translate<ReactElement>('levels.level_one_seller.title_description'),
  },
  highlyResponsive: {
    Icon: () => <LightningIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('gig_seller_highlights.highlights.highlyResponsive.title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.highlyResponsive.description'),
  },
  fiverrChoice: {
    Icon: () => <FiverrIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('gig_seller_highlights.highlights.fiverrChoice.title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.fiverrChoice.description'),
  },
  proFreelancer: {
    Icon: () => <VettedIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: ({ isAgency }) =>
      isAgency
        ? translate<ReactElement>('gig_seller_highlights.highlights.proFreelancer.title_agency')
        : translate<ReactElement>('gig_seller_highlights.highlights.proFreelancer.title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.proFreelancer.description'),
  },
  businessExperienced: {
    Icon: () => <SuitcaseIcon size={ICON_SIZE} color={ICON_COLOR} />,
    Title: () => translate<ReactElement>('gig_seller_highlights.highlights.businessExperienced.title'),
    DescriptionText: () => translate<ReactElement>('gig_seller_highlights.highlights.businessExperienced.description'),
  },
} satisfies Record<HighlightType, HighlightConfig>;
