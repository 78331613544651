import { dateTimeFormat, DATETIME_ELEMENT_STYLES } from '@fiverr-private/localization';
import { NotableClient } from '../interfaces/notableClient';

const getDisplayDate = (date?: string): null | string => {
    if (!date) {
        return null;
    }

    return dateTimeFormat({
        value: new Date(date),
        options: {
            year: DATETIME_ELEMENT_STYLES.NUMERIC,
            month: DATETIME_ELEMENT_STYLES.SHORT,
        },
    });
};

export const getDatePeriod = ({ startedAt, endedAt }: Pick<NotableClient, 'startedAt' | 'endedAt'>) => {
    if (!startedAt && !endedAt) {
        return null;
    }

    const startDate = getDisplayDate(startedAt);
    const endDate = getDisplayDate(endedAt);

    if (startDate && endDate) {
        return `${startDate}-${endDate}`;
    }

    return startDate || endDate;
};
