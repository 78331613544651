import React from 'react';
import { isEmpty } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { useSellerInfoContext } from '../../../../context/SellerInfoContext';
import { SummaryItemWrapper } from './SummaryItemWrapper';

export const SpokenLanguagesSummaryItem: React.FC = () => {
  const { spokenLanguages, isAgency } = useSellerInfoContext();

  if (isEmpty(spokenLanguages)) {
    return null;
  }

  const languages = spokenLanguages?.join(', ');
  const spokenLanguagesLabel = isAgency
    ? languages
    : translate('gig_seller_info.summary.speaks.label', { params: { languages } });

  return (
    <SummaryItemWrapper type="speaks">
      <Text size="b_sm" as="span">
        {spokenLanguagesLabel}
      </Text>
    </SummaryItemWrapper>
  );
};
