import React from 'react';
import { Card } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { NotableClient } from '../../../../interfaces/notableClient';
import { DatePeriod } from './DatePeriod';

interface NotableClientCardWrapperProps {
  notableClient: NotableClient;
}

export const NotableClientCardWrapper: React.FC<NotableClientCardWrapperProps> = ({ notableClient, children }) => {
  const { startedAt, endedAt, interactionDescription } = notableClient;

  return (
    <Card padding="6" borderRadius="lg">
      {children}
      <Text marginTop="3">{interactionDescription}</Text>
      <DatePeriod startedAt={startedAt} endedAt={endedAt} />
    </Card>
  );
};
