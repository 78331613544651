import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '../../interfaces/biEvents';
import { NotableClient } from '../../interfaces';
import { getBaseActionsParams } from './base';

interface BqClickedOnShowMoreNotableClientsProps extends BqEventEnrichmentDataProps {
    notableClients: NotableClient[];
}

export const bqClickedOnShowMoreNotableClients = ({
    notableClients,
    enrichmentData,
}: BqClickedOnShowMoreNotableClientsProps) =>
    merge({}, getBaseActionsParams('clicked_on_show_more_notable_clients', enrichmentData), {
        page: {
            element: {
                type: 'button',
                text: `Show all (${notableClients.length})`,
            },
        },
    });
