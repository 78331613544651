import React from 'react';
import { Types } from '@fiverr-private/theme';
import { NotableClientCardRow } from './NotableClientCard/NotableClientCardRow';
import { NotableClientCardColumn } from './NotableClientCard/NotableClientCardColumn';
import { NotableClientCardProps } from './NotableClientsCards';

export type CardDirection = 'row' | 'column';
interface CardLayout {
    Card: React.FC<NotableClientCardProps>;
    gridTemplateColumns: Types.ResponsiveGridTemplatesType;
}

export const directionToCardsLayout: Record<CardDirection, CardLayout> = {
    row: {
        Card: NotableClientCardRow,
        gridTemplateColumns: { default: '100%', md: '50%_50%' },
    },
    column: {
        Card: NotableClientCardColumn,
        gridTemplateColumns: '100%',
    },
};
