import { compact, get, sortBy, uniq } from 'lodash';
import { MetadataItemProps } from '@fiverr-private/gig_info';
import { TranslatedMetadataAttribute } from '../../types/gig/interfaces';

/**
 * Receives metadata attributes and returns an array of formatted metadata attributes.
 */
export const getFormattedMetadataAttributes = (attributes: TranslatedMetadataAttribute[]): MetadataItemProps[] => {
    const formattedAttributes: (MetadataItemProps | null)[] = attributes.map(({ name, options, metadata }) => {
        const validOptions = uniq(compact(options));
        const sortedOptions = sortBy(validOptions, (option) => get(metadata, `metadataOptions.${option}`, 0));
        const filteredOptions = compact(sortedOptions);

        if (!(name && metadata && filteredOptions.length > 0)) {
            return null;
        }

        return {
            label: metadata.label,
            key: metadata.name,
            items: filteredOptions,
        };
    });

    return compact(formattedAttributes);
};
