const MAX_LENGTH = 60;

interface GetMetadataItemsToShowProps {
    label: string;
    items: string[];
    maxLength?: number;
}

type GetMetadataItemsToShow = ({ label, items, maxLength }: GetMetadataItemsToShowProps) => number;

// A function that receives a string `label` and a list of strings `items`.
// given `maxLength`, finds the number of items `n` that satisfies:
// label.length + items.slice(n).join('').length <= maxLength
export const getMetadataItemsToShow: GetMetadataItemsToShow = ({ label, items, maxLength = MAX_LENGTH }) => {
    let n = 0;
    let length = label.length;
    while (n < items.length && length + items[n].length <= maxLength) {
        length += items[n].length;
        n++;
    }
    return n;
};
