import React, { useState } from 'react';
import { last } from 'lodash';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { mpClickedSkillsShowMore } from '../../../../../events/mixpanel/clickedSkillsShowMore';
import { bqClickedOnShowMoreGigMetadata } from '../../../../../events/bigQuery/clickedOnShowMoreGigMetadata';
import { getMetadataItemsToShow } from './utils/getMetadataItemsToShow';
import { HiddenItemsLabel } from './HiddenItemsLabel';
import { LabelItem } from './LabelItem';

interface LabelItemsProps {
  label: string;
  items: string[];
}

const useSeeMore = ({ label, items }) => {
  const [seeMore, setSeeMore] = useState(false);
  const numberOfItemsToShow = seeMore ? items.length : getMetadataItemsToShow({ label, items });
  const itemsToShow = items.slice(0, numberOfItemsToShow);
  const hiddenItems = items.slice(numberOfItemsToShow);
  const hasHiddenItems = hiddenItems.length > 0;
  const expandSeeMore = () => setSeeMore(true);

  return {
    itemsToShow,
    hiddenItems,
    hasHiddenItems,
    expandSeeMore,
  };
};

export const LabelItems: React.FC<LabelItemsProps> = ({ label, items }) => {
  const { biEvents } = useGigPageContext();
  const { itemsToShow, hiddenItems, hasHiddenItems, expandSeeMore } = useSeeMore({ label, items });

  const onSeeMoreClick = () => {
    biEvents.sendMixPanelEvent(mpClickedSkillsShowMore());
    biEvents.sendRawBigQueryEvent(
      bqClickedOnShowMoreGigMetadata({
        label,
        items,
        hiddenItemsCount: hiddenItems.length,
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );
    expandSeeMore();
  };

  return (
    <>
      {itemsToShow.map((item) => (
        <LabelItem key={item} item={item} isLastItem={item === last(itemsToShow)} />
      ))}
      {hasHiddenItems && <HiddenItemsLabel items={hiddenItems} onClick={onSeeMoreClick} />}
    </>
  );
};
