import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '../../interfaces/biEvents';
import { getBaseImpressionParams } from './base';

export const bqHoveredOnNotableClientInformation = ({ enrichmentData }: BqEventEnrichmentDataProps) =>
    merge({}, getBaseImpressionParams('hovered_on_notable_client_information', enrichmentData), {
        page: {
            component: {
                name: 'notable_clients',
            },
            element: {
                name: 'information',
            },
        },
    });
