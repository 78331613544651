import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '@fiverr-private/gig_page_context';
import { getBaseActionsParams } from './base';

export const bqClickedOnSellerName = ({ enrichmentData }: BqEventEnrichmentDataProps) =>
    merge({}, getBaseActionsParams('clicked_on_seller_name_gig_page', enrichmentData), {
        page: {
            element: {
                type: 'button',
            },
            component: {
                name: 'nex_gig_page_seller_name',
            },
        },
        sub_group: 'website_actions',
        out_of_session: false,
    });
