import { SellerInfo } from '../../interface';
import { SendEventProps } from '../../interface/biEvents';

export const mpViewedSellerCard = (sellerInfo: SellerInfo) =>
    ({
        eventName: 'Gig Page - Viewed seller card',
        params: {
            'Number of tags': sellerInfo.tags?.length || 0,
            'Does have tagline': !!sellerInfo.title,
            'Number of completed orders': sellerInfo.completedOrders,
            'Does have seller video': !!sellerInfo.introVideoUrl,
        },
    } satisfies SendEventProps);
