import React from 'react';
import { Text, Typography } from '@fiverr-private/typography';
import { WrapItem } from '@fiverr-private/layout_components';

const SEPARATOR = '•';

interface LabelItemProps {
  item: string;
  isLastItem: boolean;
}

export const LabelItem: React.FC<LabelItemProps> = ({ item, isLastItem }) => (
  <>
    <WrapItem>
      <Text>{item}</Text>
    </WrapItem>
    {!isLastItem && (
      <WrapItem>
        <Typography paddingX="0.5" color="grey_700" size="b_xs">
          {SEPARATOR}
        </Typography>
      </WrapItem>
    )}
  </>
);
