import { merge } from 'lodash';
import { Ugc } from '../../types/ugc';
import { Description } from '../../types/gig/interfaces';
import { getCurrentContent } from '../ugc/getCurrentContent';
import { NON_EXP_XSS_WHITELIST } from '../sanitization/constants';
import { sanitize } from '../sanitization';

interface PrepareGigPageInitialPropsToGigDescriptionParams {
    description: Description;
    ugc: Ugc;
}

interface PrepareGigPageInitialPropsToGigDescriptionResult {
    sanitizedDescription: string;
}

export const prepareGigDescription = ({
    description: descriptionRaw,
    ugc,
}: PrepareGigPageInitialPropsToGigDescriptionParams): PrepareGigPageInitialPropsToGigDescriptionResult => {
    const { description: descriptionUgc } = getCurrentContent({ ugc }) ?? {};
    const description = merge({}, descriptionRaw, descriptionUgc);

    return {
        sanitizedDescription: sanitize(description.content, NON_EXP_XSS_WHITELIST) ?? '',
    };
};
