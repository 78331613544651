import { BigQueryEnrichmentData } from '../../interfaces/biEvents';

const getBaseParams = (eventName: string, enrichmentData: BigQueryEnrichmentData) => ({
    type: eventName,
    entity: 'client',
    user: { id: enrichmentData.userId },
    seller: { id: enrichmentData.sellerId },
    gig: { id: enrichmentData.gigId },
    page: {
        name: enrichmentData.pageName,
        ctx_id: enrichmentData.pageCtxId,
    },
});

export const getBaseActionsParams = (eventName: string, enrichmentData: BigQueryEnrichmentData) => ({
    ...getBaseParams(eventName, enrichmentData),
    group: 'user_actions',
    action: { type: 'click' },
});
