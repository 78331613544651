import React from 'react';
import { WrapItem } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';

interface LabelTitleProps {
  label: string;
}

export const LabelTitle: React.FC<LabelTitleProps> = ({ label }) => {
  const title = `${label}:`;

  return (
    <WrapItem>
      <Text fontWeight="bold" whiteSpace="nowrap">
        {title}
      </Text>
    </WrapItem>
  );
};
