import React from 'react';
import { isNumber } from 'lodash';
import { numberFormat } from '@fiverr-private/localization';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { useSellerInfoContext } from '../../../../context/SellerInfoContext';
import { roundOrdersCount } from './utils/roundOrdersCount';
import { SummaryItemWrapper } from './SummaryItemWrapper';

export const CompletedOrdersSummaryItem: React.FC = () => {
  const { completedOrders } = useSellerInfoContext();

  if (!isNumber(completedOrders) || completedOrders < 1) {
    return null;
  }

  const count = numberFormat({ value: roundOrdersCount(completedOrders) });

  return (
    <SummaryItemWrapper type="completedOrders">
      <Text size="b_sm">
        <I18n k="gig_seller_info.summary.completed_orders" params={{ count }} />
      </Text>
    </SummaryItemWrapper>
  );
};
