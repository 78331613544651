import { useState } from 'react';

const DEFAULT_MAX_WIDTH = 200;

interface UseExpandableTextProps {
    text: string;
    maxLength?: number;
}

interface UseExpandableTextReturn {
    text: string;
    isExpandable: boolean;
    isExpanded: boolean;
    toggleExpanded: () => void;
}

type UseExpandableText = ({ text, maxLength }: UseExpandableTextProps) => UseExpandableTextReturn;

export const useExpandableText: UseExpandableText = ({ text, maxLength = DEFAULT_MAX_WIDTH }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => setIsExpanded((prev) => !prev);

    const isExpandable = text.length > maxLength;
    const textToDisplay = isExpanded || !isExpandable ? text : `${text.slice(0, maxLength)}...`;

    return {
        text: textToDisplay,
        isExpandable,
        isExpanded,
        toggleExpanded,
    };
};
