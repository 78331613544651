import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { SellerRating as SellerRatingUI, type SellerRatingProps as SellerRatingUIProps } from '../../SellerRating';
import { useSellerInfoContext } from '../../../context/SellerInfoContext';

export const SellerRating: React.FC = () => {
  const { rating: ratingData } = useSellerInfoContext();
  if (!ratingData) return null;

  const { rating, ratingsCount, onReviewsClick } = ratingData;
  return <SellerRatingResponsive rating={rating} ratingsCount={ratingsCount} onReviewsClick={onReviewsClick} />;
};

type SellerRatingResponsiveProps = Omit<SellerRatingUIProps, 'withFiveStars' | 'withWordReviews'>;
const SellerRatingResponsive = (props: SellerRatingResponsiveProps) => (
  <>
    <Container hidden={{ sm: true }}>
      <SellerRatingUI {...props} />
    </Container>
    <Container hidden={{ default: true, sm: false }}>
      <SellerRatingUI withFiveStars {...props} />
    </Container>
  </>
);
