import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Link } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { Box } from '@fiverr-private/theme';
import { useExpandableText } from '../../hooks/useExpandableText';
import { BiEvents } from '../../interfaces/biEvents';
import { mpShowMoreClicked } from '../../events/mixpanel/mpShowMoreClicked';
import { bqClickedOnShowMoreAtGigDescription } from '../../events/bigQuery/clickedOnShowMoreAtGigDescription';

import { gigDescriptionClassName } from './GigDescription.vs.css';

export interface HTMLMarkup {
  __html: string;
}
const createMarkup = (data): HTMLMarkup => ({ __html: data });

export interface GigDescriptionProps {
  sanitizedDescription: string;
  biEvents: BiEvents;
}

export const GigDescription: React.FC<GigDescriptionProps> = ({ sanitizedDescription = '', biEvents }) => {
  const { isExpandable, isExpanded, toggleExpanded } = useExpandableText({ text: sanitizedDescription });
  const linkTextKey = isExpanded
    ? 'gig_info.description.read_more_cta.expanded'
    : 'gig_info.description.read_more_cta.collapsed';
  const markupDescription = createMarkup(sanitizedDescription);

  const onClick = () => {
    if (!isExpanded) {
      biEvents.sendMixPanelEvent(mpShowMoreClicked());
      biEvents.sendRawBigQueryEvent(
        bqClickedOnShowMoreAtGigDescription({ enrichmentData: biEvents.getBigQueryEnrichmentData() })
      );
    }

    toggleExpanded();
  };

  if (!sanitizedDescription) {
    return null;
  }

  return (
    <Stack direction="column" gap="1">
      <Box
        className={gigDescriptionClassName}
        overflowWrap="anywhere"
        color="grey_1100"
        overflow={isExpanded ? undefined : 'hidden'}
        maxHeight={isExpanded ? undefined : '72px'}
        dangerouslySetInnerHTML={markupDescription}
      />
      {isExpandable && (
        <Stack width="auto">
          <Link onClick={onClick} cursor="pointer" fontWeight="bold">
            <I18n k={linkTextKey} />
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
