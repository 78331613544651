import React from 'react';
import { Grid } from '@fiverr-private/layout_components';
import { NotableClient } from '../../../interfaces/notableClient';
import { CardDirection, directionToCardsLayout } from './NotableClientsCards.config';

export interface NotableClientCardProps {
  notableClient: NotableClient;
}

interface NotableClientsCardsProps {
  notableClients: NotableClient[];
  direction?: CardDirection;
}

export const NotableClientsCards: React.FC<NotableClientsCardsProps> = ({ notableClients, direction = 'row' }) => {
  const { Card, gridTemplateColumns } = directionToCardsLayout[direction];

  return (
    <Grid templateColumns={gridTemplateColumns} gap="4">
      {notableClients.map((notableClient) => (
        <Card key={notableClient.name} notableClient={notableClient} />
      ))}
    </Grid>
  );
};
