import React from 'react';
import { SellerInfo } from '../../interface';
import { MetricsController } from '../../interface/biEvents';

export interface SellerCardContextProviderProps {
    sellerInfo: SellerInfo;
    metric: MetricsController;
}

export const SellerCardContext = React.createContext<SellerCardContextProviderProps>(
    {} as SellerCardContextProviderProps
);

export const useSellerCardContext = () => {
    const context = React.useContext(SellerCardContext);

    if (context === undefined) {
        throw new Error('useSellerCardContext must be used within a SellerCardContextProvider');
    }

    return context;
};
