import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Highlight } from '../../interfaces';
import { highlightsConfig } from './SellerHighlight.config';

interface SellerHighlightProps {
  isAgency: boolean;
  highlight: Highlight;
}

export const SellerHighlight: React.FC<SellerHighlightProps> = ({ isAgency, highlight }) => {
  const { type, extraData = {} } = highlight;
  const { Icon, Title, DescriptionText } = highlightsConfig[type];

  return (
    <Stack>
      <Icon />
      <Stack direction="column" gap="1">
        <Text fontWeight="bold">
          <Title isAgency={isAgency} />
        </Text>
        <Text color="bodySecondary" size="b_sm">
          <DescriptionText {...extraData} />
        </Text>
      </Stack>
    </Stack>
  );
};
