import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { BookConsultationButtonTheme } from '@fiverr-private/consultation';
import { SellerInfoProvider, useSellerInfoContext } from '../../context/SellerInfoContext';
import { SellerInfoData } from '../../types';
import { ProfileImage } from '../ProfileImage/ProfileImage';
import { SellerSummary } from './SellerSummary/SellerSummary';
import { SellerBadges } from './SellerBadges';
import { SellerProfession } from './SellerProfession';
import { SellerRating } from './SellerRating';
import { ContactSeller } from './ContactSeller';
import { BookConsultation, BookConsultationProps } from './BookConsultation';
import SellerName from './SellerName';

interface SellerProfileInfoProps {
  data: SellerInfoData;
}

export const SellerProfileInfo: React.FC<SellerProfileInfoProps> = ({ data }) => {
  const { sellerId, gigId, displayName, profileImage, currencyConverter, consultationData } = useSellerInfoContext();

  const bookConsultationProps = {
    sellerId,
    gigId,
    displayName,
    profileImage,
    currencyConverter,
    consultationData,
    theme: BookConsultationButtonTheme.lightGrey,
  } satisfies BookConsultationProps;

  return (
    <SellerInfoProvider value={data}>
      <Stack gap="6">
        <ProfileImage />
        <Stack gap="1.5" direction="column">
          <Stack
            gap={{ default: '0.5', sm: '4' }}
            direction={{ default: 'column', sm: 'row' }}
            alignItems={{ default: undefined, sm: 'center' }}
          >
            <SellerName />
            <SellerBadges />
          </Stack>
          <Stack gap={{ default: '0.5', sm: '4' }} direction={{ default: 'column', sm: 'row' }}>
            <SellerProfession />
            <SellerRating />
          </Stack>
          <SellerSummary />
          <Stack gap="4">
            <ContactSeller />
            <BookConsultation {...bookConsultationProps} />
          </Stack>
        </Stack>
      </Stack>
    </SellerInfoProvider>
  );
};
