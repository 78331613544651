import { NotableClient } from '@fiverr-private/gig_notable_clients';
import { NotableClient as RawNotableClient } from '../../types/gig/interfaces';
import { camelize } from '../transform/camelize';

interface PrepareGigPageInitialPropsGigNotableClientsParams {
    notableClients: RawNotableClient[];
}

interface PrepareGigPageInitialPropsGigNotableClientsResult {
    notableClients: NotableClient[];
}

export const prepareNotableClientsProps = ({
    notableClients,
}: PrepareGigPageInitialPropsGigNotableClientsParams): PrepareGigPageInitialPropsGigNotableClientsResult => ({
    notableClients: camelize<RawNotableClient[], NotableClient[]>(notableClients),
});
