import React from 'react';
import { Text } from '@fiverr-private/typography';
import { WrapItem, Divider } from '@fiverr-private/layout_components';

interface LabelItemProps {
  item: string;
  isLastItem: boolean;
}

export const LabelItem: React.FC<LabelItemProps> = ({ item, isLastItem }) => (
  <>
    <WrapItem>
      <Text>{item}</Text>
    </WrapItem>
    {!isLastItem && (
      <WrapItem>
        <Divider variant="dot" marginX="0.5" />
      </WrapItem>
    )}
  </>
);
