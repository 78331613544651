import React from 'react';
import { Text } from '@fiverr-private/typography';
import { getDatePeriod } from '../../../../utils/getDatePeriod';
import { NotableClient } from '../../../../interfaces/notableClient';

type DatePeriodProps = Pick<NotableClient, 'startedAt' | 'endedAt'>;

export const DatePeriod: React.FC<DatePeriodProps> = ({ startedAt, endedAt }) => {
  const datePeriod = getDatePeriod({ startedAt, endedAt });

  if (!datePeriod) {
    return null;
  }

  return (
    <Text size="b_sm" color="bodySecondary" marginTop="3">
      {datePeriod}
    </Text>
  );
};
