import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { Highlight, HighlightType } from '@fiverr-private/gig_seller_highlights';
import { BiEvents } from '@fiverr-private/gig_page_context';
import { Seller, SellerCard } from '../../types/seller';
import { RepeatScore } from '../../types/gig/interfaces';
import { isFiverrChoice } from '../fiverrChoice';
import { reportSellerHighlightsEvents } from '../../components/GigPage/Layouts/reportSellerHighlightsEvents';

const achievementToHighlightType: Record<number, HighlightType> = {
    1: 'level1',
    2: 'level2',
    5: 'topRated',
};

interface PrepareGigPageInitialPropsGigSellerHighlightsParams {
    seller: Seller;
    sellerCard: SellerCard;
    repeatScore: RepeatScore;
    choiceEligibilities: unknown[];
    biEvents: BiEvents;
    isBusinessLayout?: boolean;
    isProRebrandingLayout?: boolean;
}

interface PrepareGigPageInitialPropsGigSellerHighlightsResult {
    highlights: Highlight[];
    onHighlightsImpression: () => void;
}

export const prepareSellerHighlights = ({
    seller,
    sellerCard,
    repeatScore,
    choiceEligibilities,
    isBusinessLayout = false,
    isProRebrandingLayout = false,
    biEvents,
}: PrepareGigPageInitialPropsGigSellerHighlightsParams): PrepareGigPageInitialPropsGigSellerHighlightsResult => {
    const { queryParameters, pathParameters } = getContext();
    const { username: sellerUsername } = pathParameters;
    const sellerName = seller?.user?.profile?.displayName || sellerUsername;
    const sellerLevel = isBusinessLayout ? undefined : achievementToHighlightType[sellerCard.achievement];
    const hasRepeatScore = !isEmpty(repeatScore);
    const isHighlyResponsive = seller?.isHighlyResponsive;
    const isPro = sellerCard?.isPro;
    const isTopRated = sellerLevel === 'topRated';
    const isChoice = isFiverrChoice({ queryParameters, choiceEligibilities });
    const isProDefaultLayout = isPro && !isProRebrandingLayout;
    const isProRebrandedLayout = isPro && isProRebrandingLayout;

    const onHighlightsImpression = () => reportSellerHighlightsEvents(highlights, biEvents);

    const highlights: Highlight[] = [
        ...(isChoice ? [{ type: 'fiverrChoice' } as Highlight] : []),
        ...(isProDefaultLayout ? [{ type: 'pro' } as Highlight] : []),
        ...(isProRebrandedLayout ? ([{ type: 'proFreelancer' }, { type: 'businessExperienced' }] as Highlight[]) : []),
        ...(isTopRated && !isPro ? [{ type: 'topRated' } as Highlight] : []),
        ...(hasRepeatScore ? [{ type: 'loyaltySeller', extraData: { sellerName } } as Highlight] : []),
        ...(sellerLevel && !isTopRated && !isPro ? [{ type: sellerLevel } as Highlight] : []),
        ...(isHighlyResponsive ? [{ type: 'highlyResponsive' } as Highlight] : []),
    ];

    return { highlights, onHighlightsImpression };
};
