import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Link, Text } from '@fiverr-private/typography';
import { useExpandableText } from '../../../hooks/useExpandableText';
import { useSellerDescriptionContext } from '../../../context/SellerDescriptionContext';

interface DescriptionParams {
  onReadToggle: (isExpanded: boolean) => void;
  SkillsComponent: React.ReactNode;
}

export const Description: React.FC<DescriptionParams> = ({ onReadToggle, SkillsComponent }: DescriptionParams) => {
  const { description = '' } = useSellerDescriptionContext();
  const { isExpandable, isExpanded, toggleExpanded, text } = useExpandableText({
    text: description || '',
  });

  const linkTextKey = isExpanded
    ? 'gig_seller_description.description.read_more_cta.expanded'
    : 'gig_seller_description.description.read_more_cta.collapsed';

  const onExpandedToggle = () => {
    onReadToggle(isExpanded);
    toggleExpanded();
  };

  if (!description) {
    return null;
  }

  return (
    <Stack gap="1" direction="column" overflow="hidden">
      <Text as="span" overflow={isExpanded ? undefined : 'hidden'} maxHeight={isExpanded ? undefined : '72px'}>
        {text}
      </Text>
      {isExpanded && SkillsComponent}
      {isExpandable && (
        <Stack width="auto">
          <Link onClick={onExpandedToggle} cursor="pointer" fontWeight="semibold">
            <I18n k={linkTextKey} />
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
