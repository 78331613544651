import React from 'react';
import { Text } from '@fiverr-private/typography';
import { useSellerInfoContext } from '../../../../context/SellerInfoContext';
import { SummaryItemWrapper } from './SummaryItemWrapper';

export const LocationSummaryItem: React.FC = () => {
  const { location } = useSellerInfoContext();

  if (!location) {
    return null;
  }

  return (
    <SummaryItemWrapper type="location">
      <Text size="b_sm">{location}</Text>
    </SummaryItemWrapper>
  );
};
