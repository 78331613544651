import React from 'react';
import { Link } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { WrapItem } from '@fiverr-private/layout_components';

interface HiddenItemsLabelProps {
  items: string[];
  onClick: () => void;
}

export const HiddenItemsLabel: React.FC<HiddenItemsLabelProps> = ({ items, onClick }) => (
  <WrapItem>
    <Link onClick={onClick} cursor="pointer">
      <I18n k="gig_seller_card.summary.speaks.more_items" params={{ count: items.length }} />
    </Link>
  </WrapItem>
);
