import React from 'react';
import { isEmpty } from 'lodash';
import { Wrap } from '@fiverr-private/layout_components';
import { translate } from '@fiverr-private/i18n-react';
import { useSellerCardContext } from '../../../SellerCardContext/SellerCardContext';
import { LabelTitle } from './LabelTitle';
import { LabelItems } from './LabelItems/LabelItems';

export const Skills: React.FC = () => {
  const {
    sellerInfo: { skills },
  } = useSellerCardContext();

  if (!skills || isEmpty(skills)) {
    return null;
  }

  const label = translate('gig_seller_card.skills');
  const skillItems = skills.map(({ label }) => label);

  return (
    <Wrap gap="2" alignItems="center">
      <LabelTitle label={label} />
      <LabelItems label={label} items={skillItems} />
    </Wrap>
  );
};
