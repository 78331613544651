import { merge } from 'lodash';
import { SellerInfo } from '@fiverr-private/gig_seller_card';
import { MetadataItemProps } from '@fiverr-private/gig_info';
import { General, PathfinderWrapperType } from '@fiverr-private/gig_page_context';
import { type CatalogTextualEntity } from '../../../../gql-operations/gql-generated';
import { Seller, SellerCard, SellerLanguageParams } from '../../types/seller';
import { Ugc } from '../../types/ugc';
import { getSellerUrl } from '../getSellerUrl/getSellerUrl';
import { getCurrentContent } from '../ugc/getCurrentContent';
import ContactTrigger from '../../components/shared/ContactTrigger';
import { calcAgencyData } from '../calcAgenciesData/calcAgenciesData';
import { Badges } from '../../components/Overview/Badges/Badges';
import { translateTextualEntity } from './translateTextualEntity';
import {
    getCountryName,
    getSellerLanguagesArray,
    prepareSellerSkills,
    prepareSellerTags,
} from './prepareSellerCard.utils';

interface Overview {
    gig?: {
        rating?: number;
        ratingsCount?: number;
        professionTitle?: CatalogTextualEntity;
    };
}

interface PrepareGigPageInitialPropsToGigSellerCardParams {
    seller: Seller;
    sellerCard: SellerCard;
    sellerLanguageParams: SellerLanguageParams;
    pathfinderWrapper: PathfinderWrapperType;
    ugc: Ugc;
    sellerUsername: string;
    gigMetadata: MetadataItemProps[];
    general: General;
    overview: Overview;
    onReviewsClick: () => void;
    isProRebrandingLayout?: boolean;
}

interface PrepareGigPageInitialPropsToGigSellerCardResult {
    sellerInfo: SellerInfo;
}

export const prepareSellerCard = ({
    seller,
    sellerCard: sellerCardRaw,
    sellerLanguageParams,
    pathfinderWrapper,
    ugc,
    sellerUsername,
    gigMetadata,
    general,
    overview,
    onReviewsClick,
    isProRebrandingLayout,
}: PrepareGigPageInitialPropsToGigSellerCardParams): PrepareGigPageInitialPropsToGigSellerCardResult => {
    const { sellerCard: sellerCardUgc } = getCurrentContent({ ugc }) ?? {};
    const sellerCard = merge({}, sellerCardRaw, sellerCardUgc);
    const { subCategoryId } = general;
    const activeTagType = 'skill'; // TODO - next PR will add support for other tag types
    const showSkills = activeTagType !== 'skill';
    const { isAgency } = calcAgencyData({ seller });

    return {
        sellerInfo: {
            isPro: isProRebrandingLayout && sellerCard?.isPro,
            professionTitle: getProfessionTitle(overview),
            rating: getSellerInfoRating(overview, onReviewsClick),
            introVideoUrl: seller?.introVideo?.url,
            sellerUrl: getSellerUrl({ pathfinderWrapper }),
            username: sellerUsername,
            sellerName: seller?.user?.profile?.displayName || sellerUsername,
            profilePhotoUrl: sellerCard.profilePhoto,
            title: sellerCard.oneLiner,
            isAgency,
            location: getCountryName(sellerCard.countryCode),
            spokenLanguages: getSellerLanguagesArray(sellerLanguageParams.sellerLanguages),
            completedOrders: seller?.completedOrdersCount,
            description: sellerCard.description || '',
            skills: prepareSellerSkills({ showSkills, subCategoryId, sellerSkills: seller?.activeStructuredSkills }),
            tags: prepareSellerTags({
                activeTagType,
                subCategoryId,
                sellerSkills: seller?.activeStructuredSkills,
                gigMetadata,
            }),
            ContactTrigger,
            Badges,
        },
    };
};

const getSellerInfoRating = (overview: Overview, onReviewsClick: () => void): SellerInfo['rating'] | undefined => {
    if (!overview) return undefined;
    if (!overview.gig) return undefined;
    if (!overview.gig.rating) return undefined;
    return {
        rating: overview.gig.rating,
        ratingsCount: overview.gig.ratingsCount,
        onReviewsClick,
    };
};

const getProfessionTitle = (overview: Overview): SellerInfo['professionTitle'] | undefined => {
    if (!overview) return undefined;
    if (!overview.gig) return undefined;
    if (!overview.gig.professionTitle) return undefined;
    return translateTextualEntity(overview.gig.professionTitle);
};
