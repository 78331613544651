import React from 'react';
import { Text } from '@fiverr-private/typography';
import { useSellerDescriptionContext } from '../../../context/SellerDescriptionContext';

export const SellerOneLiner = () => {
  const { oneLinerTitle } = useSellerDescriptionContext();

  if (!oneLinerTitle) {
    return null;
  }

  return <Text fontWeight="semibold">{oneLinerTitle}</Text>;
};
