import React from 'react';
import { BiEvents } from '../../interfaces/biEvents';
import { NotableClient } from '../../interfaces';

export interface NotableClientsContextProviderProps {
    biEvents: BiEvents;
    notableClients: NotableClient[];
}

export const NotableClientsContext = React.createContext<NotableClientsContextProviderProps>(
    {} as NotableClientsContextProviderProps
);

export const useNotableClientsContext = () => {
    const context = React.useContext(NotableClientsContext);

    if (context === undefined) {
        throw new Error('useNotableClientsContext must be used within a NotableClientsContextProvider');
    }

    return context;
};
