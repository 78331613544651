import React from 'react';
import { Wrap } from '@fiverr-private/layout_components';
import { MetadataItemProps } from '../../../interfaces';
import { LabelTitle } from './LabelTitle';
import { LabelItems } from './LabelItems/LabelItems';

export const MetadataItem: React.FC<MetadataItemProps> = ({ label, items }) => {
  const itemsLabels = items.map(({ label }) => label);

  return (
    <Wrap gap="2" alignItems="center">
      <LabelTitle label={label} />
      <LabelItems label={label} items={itemsLabels} />
    </Wrap>
  );
};
