import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { useSellerInfoContext } from '../../../context/SellerInfoContext';
import { LocationSummaryItem } from './SummaryItems/LocationSummaryItem';
import { SpokenLanguagesSummaryItem } from './SummaryItems/SpokenLanguagesSummaryItem';
import { CompletedOrdersSummaryItem } from './SummaryItems/CompletedOrdersSummaryItem';

export const SellerSummary = () => {
  const { location, spokenLanguages, completedOrders } = useSellerInfoContext();
  const hasPropertiesToDisplay = [location, spokenLanguages, completedOrders].some((item) => item);

  if (!hasPropertiesToDisplay) {
    return null;
  }

  return (
    <Stack gap="3" width="auto" wrap="wrap">
      <LocationSummaryItem />
      <SpokenLanguagesSummaryItem />
      <CompletedOrdersSummaryItem />
    </Stack>
  );
};
