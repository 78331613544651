import React from 'react';
import { noop } from 'lodash';
import { Wrap, WrapItem } from '@fiverr-private/layout_components';
import { Link } from '@fiverr-private/typography';
import { useSellerInfoContext } from '../../context/SellerInfoContext';

const SellerName: React.FC = () => {
  const { displayName, sellerUrl, onSellerNameClick = noop } = useSellerInfoContext();

  return (
    <Wrap gap="2" direction="row" alignItems="baseline">
      <WrapItem>
        <Link
          fontSize="b_xxl"
          lineHeight="b_xxl"
          fontWeight="bold"
          decoration="none"
          href={sellerUrl}
          onClick={onSellerNameClick}
        >
          {displayName}
        </Link>
      </WrapItem>
    </Wrap>
  );
};

export default SellerName;
