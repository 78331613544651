const MAX_NUMBER_TO_SHOW_EXACT = 1000;
const AMOUNT_JUMPS = 100;

export const roundOrdersCount = (ordersCount: number) => {
    if (ordersCount < MAX_NUMBER_TO_SHOW_EXACT) {
        return ordersCount;
    }

    return Math.floor(ordersCount / AMOUNT_JUMPS) * AMOUNT_JUMPS;
};
