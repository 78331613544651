import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '@fiverr-private/gig_page_context';
import { getBaseActionsParams } from './base';

export const bqClickedOnContactSeller = ({ enrichmentData }: BqEventEnrichmentDataProps) =>
    merge({}, getBaseActionsParams('clicked_on_contact_seller', enrichmentData), {
        eventSource: 'contact_seller_direct',
        page: {
            element: {
                type: 'button',
            },
            component: {
                name: 'nex_contact_seller',
            },
        },
        sub_group: 'website_actions',
        out_of_session: false,
    });
