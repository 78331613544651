import { getShortLanguageCode } from '@fiverr-private/localization';
import { translate } from '@fiverr-private/i18n-react';
import { Tag } from '@fiverr-private/tag_collection';
import { ActiveStructuredSkill, SellerLanguage } from '../../types/seller';
import { PrepareSkillsProps, PrepareTagsProps } from './prepareSellerCard.interface';

export const getCountryName = (countryCode: string | undefined) => {
    if (!countryCode) {
        return '';
    }

    return translate(`countries.${countryCode}`);
};

export const getSellerLanguagesArray = (sellerLanguages: SellerLanguage[] = []): string[] =>
    sellerLanguages.map((languageObj) =>
        translate(`languages.codes.${getShortLanguageCode(languageObj.name?.toLowerCase())}`)
    );

const getSortedSellerSkills = (sellerSkills: ActiveStructuredSkill[]) => {
    const levelToNumber = {
        BEGINNER: 1,
        INTERMEDIATE: 2,
        PRO: 3,
    };

    return sellerSkills.sort((a, b) => levelToNumber[b.level] - levelToNumber[a.level]);
};

const getSkillTags = (sellerSkills: ActiveStructuredSkill[], subCategoryId: number): Tag[] => {
    const sortedSkills = getSortedSellerSkills(sellerSkills);

    return sortedSkills.map(
        ({ id, label }) =>
            ({
                label,
                type: 'skill',
                subCategoryId,
                id,
            } satisfies Tag)
    );
};

export const prepareSellerSkills = ({ showSkills, subCategoryId, sellerSkills = [] }: PrepareSkillsProps) => {
    if (!showSkills) {
        return [];
    }

    return getSkillTags(sellerSkills, subCategoryId);
};

export const prepareSellerTags = ({
    activeTagType,
    subCategoryId,
    sellerSkills = [],
    gigMetadata = [],
}: PrepareTagsProps): Tag[] => {
    if (activeTagType === 'skill') {
        return getSkillTags(sellerSkills, subCategoryId);
    }

    const relevantMetadata = gigMetadata.find(({ key }) => key === activeTagType);

    if (relevantMetadata) {
        return relevantMetadata.items.map(({ value, label }) => ({
            label,
            value,
            key: relevantMetadata.key,
            type: 'metadata',
        }));
    }

    return [];
};
