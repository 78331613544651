import React, { useState } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Modal, ModalHeader, ModalTrigger, ModalContent, ModalCloseBtn, ModalBody } from '@fiverr-private/overlay';
import { Box } from '@fiverr-private/theme';
import { useNotableClientsContext } from '../NotableClientsContext/NotableClientsContext';
import { mpShowAllClicked } from '../../events/mixpanel/mpShowAllClicked';
import { bqClickedOnShowMoreNotableClients } from '../../events/bigQuery/clickedOnShowMoreNotableClients';
import { NotableClientsCards } from './NotableClientsCards/NotableClientsCards';
import { CLIENTS_TO_PREVIEW } from './NotableClients.config';

export const NotableClientsModalButton: React.FC = () => {
  const { biEvents, notableClients } = useNotableClientsContext();
  const [isOpen, setIsOpen] = useState(false);
  const notableClientsCount = notableClients.length;

  if (notableClientsCount <= CLIENTS_TO_PREVIEW) {
    return null;
  }

  const openModal = () => {
    biEvents.sendMixPanelEvent(mpShowAllClicked());
    biEvents.sendRawBigQueryEvent(
      bqClickedOnShowMoreNotableClients({
        notableClients,
        enrichmentData: biEvents.getBigQueryEnrichmentData(),
      })
    );
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalTrigger>
          <Box
            as="button"
            color="bodyPrimary"
            decoration="underline"
            fontSize="b_md"
            lineHeight="b_md"
            fontWeight="bold"
            cursor="pointer"
            onClick={openModal}
          >
            <I18n k="gig_notable_clients.modal.open_modal_cta" params={{ count: notableClientsCount }} />
          </Box>
        </ModalTrigger>
        <ModalContent>
          <ModalHeader>
            <I18n k="gig_notable_clients.modal.header" params={{ count: notableClientsCount }} />
            <ModalCloseBtn />
          </ModalHeader>
          <ModalBody>
            <NotableClientsCards notableClients={notableClients} direction="column" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
