import React from 'react';
import { BoxIcon, CommentIcon, PinIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';

const ITEMS_COLOR = 'grey_1100';

type SummaryItemTypes = 'location' | 'speaks' | 'completedOrders';

const summaryItemIcon: Record<SummaryItemTypes, React.FC> = {
  location: () => <PinIcon color={ITEMS_COLOR} />,
  speaks: () => <CommentIcon color={ITEMS_COLOR} />,
  completedOrders: () => <BoxIcon color={ITEMS_COLOR} />,
};

interface SummaryItemWrapperProps {
  type: SummaryItemTypes;
}

export const SummaryItemWrapper: React.FC<SummaryItemWrapperProps> = ({ type, children }) => {
  const ItemIcon = summaryItemIcon[type];

  return (
    <Stack gap="1" width="auto">
      <Stack width="auto" flexShrink="0">
        <ItemIcon />
      </Stack>
      {children}
    </Stack>
  );
};
