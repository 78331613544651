import React from 'react';
import { BiEvents } from '../../interfaces/biEvents';

export interface GigInfoContextProviderProps {
    biEvents: BiEvents;
}

export const GigInfoContext = React.createContext<GigInfoContextProviderProps>({} as GigInfoContextProviderProps);

export const useGigInfoContext = () => {
    const context = React.useContext(GigInfoContext);

    if (context === undefined) {
        throw new Error('useGigInfoContext must be used within a GigInfoContextProvider');
    }

    return context;
};
