import { isPlainObject, isArray, camelCase } from 'lodash';

/**
 * TS guard to validate if variable is an object
 * @param obj
 */
const isObject = (obj: unknown): obj is Record<string, unknown> => isPlainObject(obj);

/**
 * Recursively camelize keys of an object. An object will recusively camelize all keys of its children.
 * An array will camelize all items in the array.
 * any other will be unchanged
 * @example camelize({ foo_bar: 1 }) // { fooBar: 1 }
 * @param obj Object to camelize
 */
export const camelize = <Src, Dest>(obj: Src): Dest => {
    if (isObject(obj)) {
        return Object.keys(obj).reduce((acc, key) => {
            acc[camelCase(key)] = camelize(obj[key]);
            return acc;
        }, {} as Dest);
    }

    if (isArray(obj)) {
        return obj.map(camelize) as unknown as Dest;
    }

    return obj as unknown as Dest;
};
