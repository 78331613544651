import React from 'react';
import { Tooltip } from '@fiverr-private/tooltip';
import { InfoCircleIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';
import { useNotableClientsContext } from '../../../NotableClientsContext/NotableClientsContext';
import { mpBrandTooltipDisplayed } from '../../../../events/mixpanel/mpBrandTooltipDisplayed';
import { bqHoveredOnNotableClientInformation } from '../../../../events/bigQuery/hoveredOnNotableClientInformation';

interface ClientInfoTooltipProps {
  description?: string;
}

export const ClientInfoTooltip: React.FC<ClientInfoTooltipProps> = ({ description }) => {
  const { biEvents } = useNotableClientsContext();

  if (!description) {
    return null;
  }

  const onViewTooltip = (isOpen: boolean) => {
    if (isOpen) {
      biEvents.sendMixPanelEvent(mpBrandTooltipDisplayed());
      biEvents.sendRawBigQueryEvent(
        bqHoveredOnNotableClientInformation({ enrichmentData: biEvents.getBigQueryEnrichmentData() })
      );
    }
  };

  return (
    <Stack width="auto" visibility={{ default: 'hidden', md: 'visible' }}>
      <Tooltip content={description} onOpenChanged={onViewTooltip}>
        <InfoCircleIcon color="grey_900" />
      </Tooltip>
    </Stack>
  );
};
