import { useRef, RefObject } from 'react';
import { useEnteredView } from '@fiverr-private/hooks';

interface UseImpressionableRefProps {
    onImpression: () => void;
    threshold?: number;
}

type UseImpressionableRef = (props: UseImpressionableRefProps) => RefObject<HTMLElement>;

export const useImpressionableRef: UseImpressionableRef = ({ onImpression, threshold = 0 }) => {
    const ref = useRef<HTMLElement>(null);

    useEnteredView(ref, {
        threshold,
        onEntered: onImpression,
    });

    return ref;
};
