import React from 'react';
import { useImpressionManager } from '@fiverr-private/impressionable';
import GigPage from '../../../apps/gig_page/components/GigPage';
import { NonExperientialGigPage } from '../../../apps/gig_page/components/GigPage/Layouts/NonExperientialGigPage';

const GigPageClient = (props: Record<string, unknown>) => {
  useImpressionManager();

  return (
    <GigPage {...props}>
      <NonExperientialGigPage />
    </GigPage>
  );
};

export default GigPageClient;
