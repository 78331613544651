import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { SellerDescriptionData } from '../../types';
import { SellerDescriptionProvider } from '../../context/SellerDescriptionContext';
import { SellerOneLiner } from './SellerOneLiner/SellerOneLiner';
import { Description } from './Description/Description';

interface Props {
  data: SellerDescriptionData;
  onReadToggle: (isExpanded: boolean) => void;
  SkillsComponent: React.ReactNode;
}

export const SellerDescription: React.FC<Props> = ({ data, onReadToggle, SkillsComponent }) => (
  <SellerDescriptionProvider value={data}>
    <Stack direction="column" gap="2">
      <SellerOneLiner />
      <Description onReadToggle={onReadToggle} SkillsComponent={SkillsComponent} />
    </Stack>
  </SellerDescriptionProvider>
);
