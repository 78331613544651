import { createContext, useContext } from 'react';
import { SellerDescriptionData } from '../types';

export const SellerDescriptionContext = createContext<SellerDescriptionData>({
    skills: [],
    description: null,
});

export const SellerDescriptionProvider = SellerDescriptionContext.Provider;

export const useSellerDescriptionContext = () => useContext(SellerDescriptionContext);
