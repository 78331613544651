import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '@fiverr-private/gig_page_context';
import { getBaseActionsParams } from './base';

export const bqClickedOnShowMoreAtSellerDescription = ({ enrichmentData }: BqEventEnrichmentDataProps) =>
    merge({}, getBaseActionsParams('clicked_on_show_more_at_seller_description', enrichmentData), {
        page: {
            element: {
                type: 'button',
                text: 'Read More',
            },
            component: {
                name: 'seller_description',
            },
        },
    });
