import { BiEvents } from '@fiverr-private/gig_page_context';
import { Highlight } from '@fiverr-private/gig_seller_highlights';

export const reportSellerHighlightsEvents = (highlights: Highlight[], biEvents: BiEvents) => {
    const enrichmentData = biEvents.getBigQueryEnrichmentData();
    const { userId, sellerId, gigId, pageName, pageCtxId } = enrichmentData;

    const bqEventParams = {
        type: 'seller_highlights_in_gig_page_is_shown',
        entity: 'client',
        user: { id: userId },
        seller: { id: sellerId },
        gig: { id: gigId },
        page: {
            name: pageName,
            ctx_id: pageCtxId,
            component: {
                name: 'highlights',
                metadata: JSON.stringify({ highlights_names: highlights.map(({ type }) => type).join(', ') }),
            },
            element: {
                key: `${highlights.length}`,
            },
        },
        group: 'user_impression',
        action: { type: 'impression' },
    };

    biEvents.sendMixPanelEvent({ eventName: 'Gig page - Highlights viewed' });
    biEvents.sendRawBigQueryEvent(bqEventParams);
};
