import React from 'react';
import { noop } from 'lodash';
import { Stack } from '@fiverr-private/layout_components';
import { SellerHighlight } from '../SellerHighlight';
import { useImpressionableRef } from '../../hooks/useImpressionableRef';
import { Highlight } from '../../interfaces';

interface SellerHighlightsListProps {
  highlights: Highlight[];
  isAgency?: boolean;
  onImpression?: () => void;
}

export const SellerHighlightsList: React.FC<SellerHighlightsListProps> = ({
  highlights,
  isAgency = false,
  onImpression = noop,
}) => {
  const ref = useImpressionableRef({ onImpression });

  return (
    <Stack gap="5" direction="column" ref={ref}>
      {highlights.map((highlight) => (
        <SellerHighlight key={highlight.type} isAgency={isAgency} highlight={highlight} />
      ))}
    </Stack>
  );
};
