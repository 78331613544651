import { merge } from 'lodash';
import { BqEventEnrichmentDataProps } from '../../interfaces/biEvents';
import { getBaseActionsParams } from './base';

interface BqClickedOnShowMoreGigMetadata extends BqEventEnrichmentDataProps {
    label: string;
    items: string[];
    hiddenItemsCount: number;
}

export const bqClickedOnShowMoreGigMetadata = ({
    label,
    items,
    hiddenItemsCount,
    enrichmentData,
}: BqClickedOnShowMoreGigMetadata) =>
    merge({}, getBaseActionsParams('clicked_on_show_more_gig_metadata', enrichmentData), {
        page: {
            element: {
                type: 'button',
                text: `+${hiddenItemsCount} more`,
                key: `${items.length}`,
            },
            component: {
                metadata: JSON.stringify({ type: label, values: items.join(', ') }),
            },
        },
    });
